import { useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, IconButton, InputAdornment, Alert, Collapse, Typography } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AccountCircle, Visibility, VisibilityOff, Close, VpnKey } from '@mui/icons-material';
// project imports
import AuthWrapper1 from '../AuthWrapper1';
import LogoRehagro from '../../../../components/Images/download.png';
import { authentication } from 'services/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [values, setValues] = useState({ email: '', password: '', showPassword: false });
    const [open, setOpen] = useState(true);
    const [error, setError] = useState('');

    // ==============================|| STYLES ||============================== //
    const useStyles = makeStyles((theme) => ({
        margin: {
            '& label.Mui-focused': {
                // opacity: '0.42',
                color: '#015641'
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#015641',
                color: '#015641'
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid #015641'
            },
            '& .MuiInputBase-input': {
                // opacity: '0.42',
                borderBottom: '1px solid #015641',
                color: '#000000',
                marginLeft: '27px',
                font: 'normal normal normal 18px/22px Myriad Pro'
            },
            '& .MuiFormLabel-root': {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '19px',
                letterSpacing: '0.15px',
                color: '#015641',
                marginLeft: '32px'
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderColor: '#015641'
            },
            ' & .MuiInput-underline:after': {
                borderBottom: '2px solid #015641'
            },
            '& :-webkit-autofill': {
                transitionDelay: '9999s'
            },
            width: '100%',
            margin: theme.spacing(1)
        },
        banner: {
            '@media(min-width: 768px)': {
                width: '500px',
                height: '84px',
                font: 'normal normal normal 35px/42px Roboto, sans-serif',
                color: '#015641',
                marginBottom: '20%'
            },
            '@media(max-width: 768px)': {
                width: '320px',
                height: '20px',
                font: 'normal normal normal 35px/42px Roboto, sans-serif',
                color: '#015641',
                marginBottom: '50%'
            }
        },
        remember: {
            font: 'normal normal normal 14px/17px Roboto, sans-serif',
            color: '#015641',
            '.remember': {
                color: '#015641'
            }
        },
        button: {
            backgroundColor: '#015641',
            color: 'white',
            borderRadius: '10px',
            opacity: 1,
            '@media(min-width: 768px)': {
                marginLeft: '58%',
                width: '195px'
            }
        },
        buttonRemember: {
            backgroundColor: '#015641',
            color: 'white',
            borderRadius: '10px',
            opacity: 1,
            '@media(min-width: 768px)': {
                width: '195px',
                marginTop: '20px'
            }
        }
    }));
    const classes = useStyles();

    // ==============================|| CHANGE STATES ||================================= //
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // ==============================|| CHANGE SHOW PASSWORD ||============================== //
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword
        });
    };

    // ==============================|| MOUSE DOWN PASSWORD ||============================== //
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // ==============================|| POST API LOGIN ||============================== //
    const handleForm = (e) => {
        e.preventDefault();
        authentication(values)
            .then((res) => {
                localStorage.setItem('token', res.data.access_token);
                dispatch({ type: 'SET_LOGIN', payload: res.data.user });
                return res;
            })

            .then((resp) => {
                resp.data.user.id_role == 1 && navigate({ pathname: '/index' });
            })
            .catch((e) => setError(e.response.data.error));
    };

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="start" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid
                            item
                            sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box
                                item
                                sx={{
                                    borderRadius: 2,
                                    padding: 2,
                                    backgroundColor: '#FFFFFF',
                                    boxShadow: [10, 10, '10em', '	#000000']
                                }}
                            >
                                <form onSubmit={handleForm}>
                                    <Grid container direction="column" justifyContent="center" alignItems="center">
                                        <Box>
                                            <img src={LogoRehagro} alt="logo rehagro" />
                                        </Box>
                                        <Box>
                                            <Typography textAlign="center" className={classes.banner}>
                                                Bem vindo(a)!
                                                <br />
                                                Faça login em sua conta
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Box sx={{ '& > :not(style)': { m: 1 } }}>
                                        {error && (
                                            <Collapse in={open}>
                                                <Alert
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => {
                                                                setOpen(false);
                                                            }}
                                                        >
                                                            <Close fontSize="inherit" />
                                                        </IconButton>
                                                    }
                                                    sx={{ mb: 2 }}
                                                    variant="filled"
                                                    severity="error"
                                                >
                                                    {error}
                                                </Alert>
                                            </Collapse>
                                        )}
                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <AccountCircle sx={{ color: '#015641', mr: -4, my: 2.5 }} />
                                            <TextField
                                                onChange={handleChange}
                                                name="email"
                                                className={classes.margin}
                                                id="input-with-sx"
                                                label="Email"
                                                variant="standard"
                                                autoComplete="new-password"
                                                type="email"
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{ '& > :not(style)': { m: 1 } }}>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <VpnKey sx={{ color: '#015641', mr: -4, my: 2 }} />
                                            <TextField
                                                onChange={handleChange}
                                                name="password"
                                                className={classes.margin}
                                                id="input-with-sx"
                                                label="Senha"
                                                variant="standard"
                                                autoComplete="new-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                sx={{ color: '#FFF' }}
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? (
                                                                    <VisibilityOff sx={{ color: '#015641' }} />
                                                                ) : (
                                                                    <Visibility sx={{ color: '#015641' }} />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className={classes.remember}>
                                        <Button variant="text" color="success" href="/emailforgotpassword">
                                            <p>Esqueceu a senha? Clique aqui</p>
                                        </Button>
                                    </Box>
                                    <Box sx={{ mt: 2 }}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                // disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="success"
                                                className={classes.button}
                                            >
                                                Entrar
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </form>
                            </Box>
                            <p color="#FFFFFF">Copyright © Rehagro 2022.</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default Login;
