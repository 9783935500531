import styled from 'styled-components';

export const PreviewPhoto = styled.div`
    width: 100vw;
    height: 50vh;
    background-image: url(${(props) => props.src});
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
`;
