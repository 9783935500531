import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SearchField from 'react-search-field';
import Tooltip from '@mui/material/Tooltip';
import { Grid, Box } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { MENU_OPEN } from 'store/actions';
import { changeStatusCourse, deleteCourse, getCourses, backToTheTopCourse, searchCourses } from 'services/course';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddButton from 'components/Buttons/AddButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#015641',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));
export default function GridCourses() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const [courses, setCourses] = React.useState([]);
    const [success, setSuccess] = React.useState('');
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [error, setError] = React.useState('');

    const [idDestroyCourse, setidDestroyCourse] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenDestroy = (idDestroy) => {
        setidDestroyCourse(idDestroy);
        setOpenModal(true);
    };
    const handleCloseModal = () => setOpenModal(false);
    const rowsPerPage = useSelector((state) => state.course.rowsPerPage);
    const page = useSelector((state) => state.course.page);

    const getAllCourses = (title) => {
        getCourses(page + 1, rowsPerPage, title).then((resp) => setCourses(resp.data));
    };
    const searchCourse = (title) => {
        searchCourses(title).then((resp) => setCourses(resp.data));
    };
    const changeStatusActive = (event) => {
        const id = event.target.id;
        let checked = event.target.checked === true ? 1 : 0;
        const data = {
            active: checked
        };
        changeStatusCourse(id, data)
            .then(() => getAllCourses())
            .catch(() => getAllCourses());
    };

    const handleChangePage = (classe, newPage) => {
        dispatch({ type: 'SET_PAGE_COURSE', payload: newPage });
    };

    const handleChangeRowsPerPage = (classe) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_COURSE', payload: parseInt(classe.target.value, 10) });
        dispatch({ type: 'SET_PAGE_COURSE', payload: 0 });
    };

    const deleteCourseById = () => {
        handleCloseModal();
        deleteCourse(idDestroyCourse)
            .then((resp) => {
                getAllCourses();
                setError('');
                setSuccess(resp.data.success);
                setidDestroyCourse('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    const getStatus = (course) => {
        if (course.active === 1) {
            return 'Postado';
        }

        if (course?.schedulingPost) {
            return 'Agendado';
        }

        return 'Desativado';
    };

    const BackTheTop = (idCourse) => {
        const data = new FormData();
        data.append('idCourse', idCourse);
        backToTheTopCourse(data, {
            headers: { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' }
        })
            .then((resp) => {
                getAllCourses();
                setError('');
                setSuccess(resp.data.success);
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    React.useEffect(() => {
        getAllCourses();
    }, [rowsPerPage, page]);

    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'Cursos');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'Cursos' });
        }
    }, []);

    return (
        <>
            <BasicModal
                open={openModal}
                title="Excluir Curso"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir o curso ?"
                onDelete={deleteCourseById}
            />
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <Grid container spacing={matchDownSM ? 2 : 2}>
                    <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                        <h1
                            style={{
                                font: 'normal normal bold 35px/44px Roboto, sans-serif',
                                letterSpacing: '0px',
                                color: 'var(--unnamed-color-015641)',
                                Color: '#015641',
                                opacity: 1,
                                padding: 15,
                                marginLeft: '2%'
                            }}
                        >
                            <FormatListBulletedIcon /> CURSOS
                        </h1>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginTop: 8 }}>
                        <Box display="flex" justifyContent="right" alignItems="right" marginRight={2}>
                            <SearchField
                                placeholder="Pesquisar..."
                                onEnter={(e) => searchCourse(e)}
                                onSearchClick={(e) => searchCourse(e)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{ width: '95%', marginTop: 0 }}></hr>
                <h3
                    style={{
                        font: 'normal normal 300 18px/22px Roboto, sans-serif',
                        letterSpacing: '0px',
                        Color: '#00000',
                        opacity: 1,
                        padding: 15,
                        marginLeft: '2%'
                    }}
                >
                    Gerencie seus cursos
                </h3>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Postar</StyledTableCell>
                            <StyledTableCell style={{ width: 200 }}>Status</StyledTableCell>
                            <StyledTableCell style={{ width: 800 }}>Título</StyledTableCell>
                            <StyledTableCell align="left">Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courses.data &&
                            courses.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>
                                        <Toggle id={`${row.id}`} checked={row.active === 1 ? true : false} onChange={changeStatusActive} />
                                    </StyledTableCell>
                                    <StyledTableCell>{getStatus(row)}</StyledTableCell>
                                    <StyledTableCell>{row.name}</StyledTableCell>
                                    <Tooltip title="Visualizar">
                                        <IconButton onClick={() => navigate({ pathname: `/curso/${row.id}/view` })}>
                                            <ViewIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Editar">
                                        <IconButton onClick={() => navigate({ pathname: `/curso/${row.id}/edit` })}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Excluir">
                                        <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                                colSpan={3}
                                count={courses.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <AddButton href="/novo_curso" />
        </>
    );
}
