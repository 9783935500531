import { useState, useEffect, useRef, forwardRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, FormHelperText, Grid, TextField, MenuItem, useMediaQuery, Alert, Snackbar } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'components/Loading/Loading';
import NumberFormat from 'react-number-format';
import { getResoucesFilters, postPublication, getPublicationPerId, updatePublication } from 'services/publication';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import SchedulingFormInput from 'components/Forms/SchedulingFormInput';
import moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DateInputNoTime from '../../../components/Forms/DateInputNoTime';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const Publication = ({ ...others }) => {
    const params = useParams();
    const themeButton = createTheme({
        status: {
            danger: '#e53e3e'
        },
        palette: {
            primary: {
                main: '#0971f1',
                darker: '#053e85'
            },
            neutral: {
                main: '#64748B',
                contrastText: '#fff'
            }
        }
    });
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 58;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const initialValuesEdit = {
        idType: '',
        office: '',
        url: '',
        company: '',
        salary: '',
        finalDate: '',
        local: '',
        idPeriodOpportunity: '',
        description: EditorState.createEmpty(),
        shortDescription: EditorState.createEmpty(),
        schedulingPost: '',
        vacationInternship: '',
        requirement: EditorState.createEmpty()
    };

    const initialStateOptions = {
        periods: []
    };

    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [options, setOptions] = useState(initialStateOptions);
    const [error, setError] = useState('');
    const [errorValidate, setErrorValidate] = useState({});
    const [disableButton, setDisableButton] = useState(false);
    const [valuesEdit, setValuesEdit] = useState(initialValuesEdit);

    const titlePage =
        params.action === 'view'
            ? 'Visualização de Oportunidade'
            : params.action === 'edit'
            ? 'Edição de Oportunidade'
            : 'Cadastro de Oportunidade';

    const isDisabled = params.action === 'view' ? true : false;

    useEffect(() => {
        viewPerId();
        getAllPeriod();
    }, [params?.id]);

    const getAllPeriod = () => {
        getResoucesFilters().then((resp) => setOptions(resp.data));
    };

    const viewPerId = () => {
        params.action &&
            getPublicationPerId(params.id).then((resp) => {
                setValuesEdit({
                    ['idType']: resp.data.idType,
                    ['office']: resp.data.office,
                    ['url']: resp.data.url,
                    ['local']: resp.data.local,
                    ['company']: resp.data.company,
                    ['finalDate']: resp.data.finalDate,
                    ['salary']: resp.data.salary || '',
                    ['idPeriodOpportunity']: resp.data.id_period_opportunity,
                    ['description']: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(`${resp.data.description}`))
                    ),
                    ['shortDescription']: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(`${resp.data.shortDescription}`))
                    ),
                    ['schedulingPost']: resp.data.schedulingPost,
                    ['vacationInternship']: resp.data.vacationInternship,
                    ['requirement']: EditorState.createWithContent(
                        ContentState.createFromBlockArray(convertFromHTML(`${resp.data.requirement}`))
                    )
                });
            });
    };

    const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value
                        }
                    });
                }}
                thousandSeparator
                isNumericString
                prefix="R$"
            />
        );
    });
    NumberFormatCustom.propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    function formatDate(date) {
        let dateFormat = new Date(date);
        let day = (dateFormat.getDate() + 1).toString();
        let month = (dateFormat.getMonth() + 1).toString();
        let year = dateFormat.getFullYear();
        return year + '-' + month + '-' + day;
    }

    return (
        <>
            <Formik
                initialValues={{
                    idType: valuesEdit.idType,
                    office: valuesEdit.office,
                    url: valuesEdit.url,
                    local: valuesEdit.local,
                    company: valuesEdit.company,
                    salary: valuesEdit.salary,
                    vacationInternship: valuesEdit.vacationInternship,
                    finalDate: valuesEdit.finalDate,
                    schedulingPost: valuesEdit?.schedulingPost ? new Date(valuesEdit.schedulingPost) : null,
                    idPeriodOpportunity: valuesEdit.idPeriodOpportunity,
                    description: valuesEdit.description,
                    shortDescription: valuesEdit.shortDescription,
                    requirement: valuesEdit.requirement,
                    submit: null
                }}
                enableReinitialize
                validationSchema={Yup.object().shape({
                    local: Yup.string().required('O campo local é obrigatório'),
                    company: Yup.string().required('O campo empresa é obrigatório')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }

                        if (convertToRaw(values.description.getCurrentContent()).blocks[0].text == '') {
                            throw 'O campo descrição é obrigatório';
                        }
                        if (convertToRaw(values.requirement.getCurrentContent()).blocks[0].text == '') {
                            throw 'O campo requisitos é obrigatório';
                        }
                        if (convertToRaw(values.shortDescription.getCurrentContent()).blocks[0].text == '') {
                            throw 'O campo descrição resumida é obrigatório';
                        }
                        if (values.vacationInternship == null || values.vacationInternship == '') {
                            throw 'O campo estágio de férias é obrigatório é obrigatório';
                        }
                        let formatFinalDate = formatDate(values.finalDate);
                        const data = new FormData();
                        setLoading(true);
                        data.append('idType', 2);
                        data.append('office', values.office);
                        data.append('url', values.url);
                        data.append('local', values.local);
                        data.append('company', values.company);
                        data.append('salary', values.salary);
                        data.append('vacationInternship', values.vacationInternship);
                        data.append('finalDate', formatFinalDate);
                        data.append('idPeriodOpportunity', values.idPeriodOpportunity);
                        data.append('description', `${draftToHtml(convertToRaw(values.description.getCurrentContent()))}`);
                        data.append('requirement', `${draftToHtml(convertToRaw(values.requirement.getCurrentContent()))}`);
                        data.append('shortDescription', `${draftToHtml(convertToRaw(values.shortDescription.getCurrentContent()))}`);

                        if (values.schedulingPost) {
                            data.append('schedulingPost', moment(values.schedulingPost).format('YYYY-MM-DD HH:mm:ss'));
                        }

                        if (params.action === 'edit') {
                            data.append('_method', 'put');
                            updatePublication(params.id, data, {
                                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                                .then((resp) => {
                                    setError('');
                                    viewPerId();
                                    setSuccess(resp.data.success);
                                    setTimeout(() => {
                                        setLoading(false);
                                        navigate('/publicacoes');
                                    }, 3000);
                                })
                                .catch((e) => {
                                    setLoading(false);
                                    setSuccess('');
                                    setErrorValidate(e.response.data.errorValidate);
                                    setError(e.response.data.error);
                                });

                            return;
                        }

                        postPublication(data, {
                            headers: { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' }
                        })
                            .then((resp) => {
                                setError('');
                                setLoading(false);
                                setSuccess(resp.data.success);
                                setTimeout(() => {
                                    navigate('/publicacoes');
                                }, 3000);
                            })
                            .catch((e) => {
                                setLoading(false);
                                setSuccess('');
                                setErrorValidate(e.response?.data?.errorValidate);
                                setError(e.response?.data?.error);
                            });
                    } catch (err) {
                        console.error(err);
                        setError(err);

                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                    <>
                        {loading && (
                            <Grid container alignItems="center" justifyContent="center">
                                <MainCard style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Loading color="#015641" type="spinningBubbles" />
                                </MainCard>
                            </Grid>
                        )}
                        <MainCard spacing={gridSpacing} style={{ padding: 15, margin: 25 }}>
                            {error || alert ? (
                                <Snackbar open={true} autoHideDuration={6000}>
                                    <Alert
                                        severity={error ? 'error' : success ? 'success' : ''}
                                        sx={{
                                            width: '100%',
                                            backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                            color: '#FFF'
                                        }}
                                    >
                                        {error ? error : success ? success : ''}
                                    </Alert>
                                </Snackbar>
                            ) : (
                                ''
                            )}
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <form noValidate onSubmit={handleSubmit} {...others}>
                                    <h3>{titlePage}</h3>
                                    <hr></hr>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.office && errors.office) || Boolean(errorValidate.office)}
                                                id="office"
                                                type="text"
                                                label="Vaga"
                                                value={values.office}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="office"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.office && errors.office
                                                        ? errors.office
                                                        : errorValidate.office
                                                        ? errorValidate.office
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            {options.periodOpportunity && (
                                                <TextField
                                                    fullWidth
                                                    error={Boolean(touched.idPeriodOpportunity && errors.idPeriodOpportunity)}
                                                    select
                                                    label="Período"
                                                    id="idPeriodOpportunity"
                                                    type="text"
                                                    value={values.idPeriodOpportunity}
                                                    name="idPeriodOpportunity"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    MenuProps={MenuProps}
                                                    disabled={isDisabled}
                                                    helperText={
                                                        touched.idPeriodOpportunity && errors.idPeriodOpportunity
                                                            ? errors.idPeriodOpportunity
                                                            : errorValidate.idPeriodOpportunity
                                                            ? errorValidate.idPeriodOpportunity
                                                            : ''
                                                    }
                                                >
                                                    {options.periodOpportunity &&
                                                        options.periodOpportunity.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.description}
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.local && errors.local) || Boolean(errorValidate.local)}
                                                id="local"
                                                type="text"
                                                label="Local"
                                                value={values.local}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="local"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.local && errors.local
                                                        ? errors.local
                                                        : errorValidate.local
                                                        ? errorValidate.local
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.url && errors.url) || Boolean(errorValidate.url)}
                                                id="url"
                                                type="text"
                                                label="URL"
                                                value={values.url}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="url"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.url && errors.url ? errors.url : errorValidate.url ? errorValidate.url : ''
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.company && errors.company) || Boolean(errorValidate.company)}
                                                id="company"
                                                type="text"
                                                label="Empresa"
                                                value={values.company}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="company"
                                                disabled={isDisabled}
                                                helperText={
                                                    touched.company && errors.company
                                                        ? errors.company
                                                        : errorValidate.company
                                                        ? errorValidate.company
                                                        : ''
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <TextField
                                                fullWidth
                                                label="Salário (mês)"
                                                value={values.salary}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="salary"
                                                id="salary"
                                                disabled={isDisabled}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} sx={{ marginTop: 3 }}>
                                            <DateInputNoTime
                                                handleBlur={() => {}}
                                                value={values.finalDate}
                                                item={{
                                                    label: 'Data de expiração',
                                                    id: 'finalDate',
                                                    minDate: new Date()
                                                }}
                                                error={null}
                                                id="finalDate"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                    </Grid>

                                    {((params.action !== 'view' && params.action !== 'edit') ||
                                        (valuesEdit && valuesEdit.schedulingPost)) && (
                                        <Grid item xs={6} sm={0} sx={{ marginTop: 0 }}>
                                            <SchedulingFormInput
                                                schedule={values.schedulingPost}
                                                setSchedule={handleChange}
                                                id="schedulingPost"
                                                label="Agendar Publicação na Timeline"
                                                minDate={new Date()}
                                                disabled={params.action === 'view'}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={6} sm={0} sx={{ marginTop: 4 }}>
                                        <FormControl>
                                            <FormLabel id="vacationInternship">Estágio de férias</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="vacationInternshipRadio"
                                                value={values.vacationInternship}
                                            >
                                                <FormControlLabel
                                                    value={0}
                                                    control={<Radio />}
                                                    label="Não"
                                                    disabled={isDisabled}
                                                    onChange={(e) => setFieldValue('vacationInternship', e.target.value)}
                                                />
                                                <FormControlLabel
                                                    value={1}
                                                    control={<Radio />}
                                                    label="Sim"
                                                    disabled={isDisabled}
                                                    onChange={(e) => setFieldValue('vacationInternship', e.target.value)}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            {values.requirement && (
                                                <>
                                                    <p>Requisitos:</p>
                                                    <Editor
                                                        editorStyle={{
                                                            height: '120px',
                                                            width: '100%',
                                                            border: '1px solid #DCDCDC',
                                                            borderRadius: '10px',
                                                            padding: '10px'
                                                        }}
                                                        toolbar={{
                                                            options: ['inline', 'list', 'textAlign', 'fontFamily', 'fontSize', 'link'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline']
                                                            },
                                                            list: {
                                                                options: ['unordered', 'ordered', 'indent', 'outdent']
                                                            },
                                                            textAlign: {
                                                                options: ['left', 'center', 'right']
                                                            },
                                                            fontFamily: {
                                                                options: [
                                                                    'Arial',
                                                                    'Georgia',
                                                                    'Impact',
                                                                    'Tahoma',
                                                                    'Times New Roman',
                                                                    'Verdana'
                                                                ]
                                                            }
                                                        }}
                                                        editorState={values.requirement}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(e) => setFieldValue('requirement', e)}
                                                        disabled={isDisabled}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            {values.description && (
                                                <>
                                                    <p>Descrição completa:</p>
                                                    <Editor
                                                        editorStyle={{
                                                            height: '200px',
                                                            width: '100%',
                                                            border: '1px solid #DCDCDC',
                                                            borderRadius: '10px',
                                                            padding: '10px'
                                                        }}
                                                        toolbar={{
                                                            options: ['inline', 'list', 'textAlign', 'fontFamily', 'fontSize', 'link'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline']
                                                            },
                                                            list: {
                                                                options: ['unordered', 'ordered', 'indent', 'outdent']
                                                            },
                                                            textAlign: {
                                                                options: ['left', 'center', 'right']
                                                            },
                                                            fontFamily: {
                                                                options: [
                                                                    'Arial',
                                                                    'Georgia',
                                                                    'Impact',
                                                                    'Tahoma',
                                                                    'Times New Roman',
                                                                    'Verdana'
                                                                ]
                                                            }
                                                        }}
                                                        editorState={values.description}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(e) => setFieldValue('description', e)}
                                                        disabled={isDisabled}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={matchDownSM ? 2 : 2}>
                                        <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                            {values.shortDescription && (
                                                <>
                                                    <p>Descrição resumida:</p>
                                                    <Editor
                                                        editorStyle={{
                                                            height: '120px',
                                                            width: '100%',
                                                            border: '1px solid #DCDCDC',
                                                            borderRadius: '10px',
                                                            padding: '10px'
                                                        }}
                                                        toolbar={{
                                                            options: ['inline', 'list', 'textAlign', 'fontFamily', 'fontSize', 'link'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline']
                                                            },
                                                            list: {
                                                                options: ['unordered', 'ordered', 'indent', 'outdent']
                                                            },
                                                            textAlign: {
                                                                options: ['left', 'center', 'right']
                                                            },
                                                            fontFamily: {
                                                                options: [
                                                                    'Arial',
                                                                    'Georgia',
                                                                    'Impact',
                                                                    'Tahoma',
                                                                    'Times New Roman',
                                                                    'Verdana'
                                                                ]
                                                            }
                                                        }}
                                                        editorState={values.shortDescription}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(e) => setFieldValue('shortDescription', e)}
                                                        disabled={isDisabled}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="end" justifyContent="end" sx={{ mt: 3 }}>
                                        <Grid item>
                                            <Box sx={{ mt: 2, mr: 3 }}>
                                                <ThemeProvider theme={themeButton}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="button"
                                                            variant="contained"
                                                            color="neutral"
                                                            onClick={() => navigate(-1)}
                                                        >
                                                            Voltar
                                                        </Button>
                                                    </AnimateButton>
                                                </ThemeProvider>
                                            </Box>
                                        </Grid>
                                        {params.action === 'view' ? (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            // disabled={isSubmitting}
                                                            component={Link}
                                                            to={`/oportunidade/${params.id}/edit`}
                                                            fullWidth
                                                            size="large"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        ) : (
                                            <Grid item>
                                                <Box sx={{ mt: 2, mr: 3 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting || disableButton}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            Salvar
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}
                                </form>
                            </div>
                        </MainCard>
                    </>
                )}
            </Formik>
        </>
    );
};

export default Publication;
