import axios from 'axios';
import { URL } from 'baseURL/baseURL';

const baseURL = URL;

const app = axios.create({
    baseURL
});

app.interceptors.request.use(
    (config) => {
        return {
            ...config,
            headers: {
                ...config.headers,
                common: {
                    ...config.headers.common,
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }
        };
    },
    (error) => Promise.reject(error)
);

const auth = axios.create({
    baseURL
});

const blob = axios.create({
    baseURL,
    responseType: 'blob'
});

blob.interceptors.request.use(
    (config) => {
        return {
            ...config,
            headers: {
                ...config.headers,
                common: {
                    ...config.headers.common,
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }
        };
    },
    (error) => Promise.reject(error)
);

export { auth, app, blob };
