import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { TablePaginationActions } from '../../../components/Pagination/TablePaginationActions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import BasicModal from '../../../components/Modal/BasicModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import SearchField from 'react-search-field';
import { Grid, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { MENU_OPEN } from 'store/actions';
import { deleteClasse, getClasses, searchClasse } from 'services/class';
import 'react-toggle/style.css';
import { Link } from 'react-router-dom';
import FilterIcon from '@mui/icons-material/FilterAlt';
import ModalFilter from 'components/Modal/ModalFilter';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ptBR from 'date-fns/locale/pt-BR';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#015641',
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

export default function GridClasses() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const page = useSelector((state) => state.classe.page);
    const rowsPerPage = useSelector((state) => state.classe.rowsPerPage);
    const [classes, setClasses] = React.useState([]);
    const [success, setSuccess] = React.useState('');
    const [error, setError] = React.useState('');
    const dateClass = useSelector((state) => state.classe.dateClass);
    const valueDateClass = useSelector((state) => state.classe.valueDateClass);
    const [idDestroyClasse, setIdDestroyClasse] = React.useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const [openModalFilter, setOpenModalFilter] = React.useState(false);
    const nameCourse = useSelector((state) => state.classe.nameCourse);

    const withLink = (to, children) => <Link to={to}>{children}</Link>;
    const actions = [
        { icon: withLink('/nova_aula', <FormatListBulletedIcon sx={{ color: '#015641', marginTop: 1 }} />), name: 'Nova Aula' },
        {
            icon: withLink('#/', <FilterIcon sx={{ color: '#015641', marginTop: 1 }} />),
            name: 'Filtros',
            operation: 'filtros'
        }
    ];

    const handleCloseModalFilter = () => setOpenModalFilter(false);

    const handleOpenDestroy = (idDestroy) => {
        setIdDestroyClasse(idDestroy);
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const handleChangePage = (classe, newPage) => {
        dispatch({ type: 'SET_PAGE_CLASSE', payload: newPage });
    };

    const handleChangeRowsPerPage = (classe) => {
        dispatch({ type: 'SET_ROWS_PER_PAGE_CLASSE', payload: parseInt(classe.target.value, 10) });
        dispatch({ type: 'SET_PAGE_CLASSE', payload: 0 });
    };

    function getAllClasses(title) {
        getClasses(page + 1, rowsPerPage, title, dateClass).then((resp) => setClasses(resp.data));
    }
    function searchClasses(title) {
        searchClasse(title, dateClass, nameCourse).then((resp) => setClasses(resp.data));
    }

    const handleChangeDateClass = (date) => {
        if (date === null) {
            dispatch({ type: 'SET_CLEAR_CLASS' });
        } else if (date) {
            let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let year = date.getFullYear();
            let newDateClass = day + '/' + month + '/' + year;
            dispatch({ type: 'SET_DATECLASS_CLASS', payload: newDateClass });
            dispatch({ type: 'SET_VALUEDATECLASS_CLASS', payload: date });
        }
    };

    const handleSubmit = () => {
        searchClasses();
        setOpenModalFilter(false);
    };

    const deleteClasseById = () => {
        handleCloseModal();
        deleteClasse(idDestroyClasse)
            .then((resp) => {
                getAllClasses();
                setError('');
                setSuccess(resp.data.success);
                setIdDestroyClasse('');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setSuccess('');
                setError(e.response.data.error);
                setTimeout(() => {
                    setError('');
                }, 4000);
            });
    };

    React.useEffect(() => {
        getAllClasses();
    }, [page, rowsPerPage]);

    React.useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === 'Aulas');
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: 'Aulas' });
        }
    }, []);

    return (
        <>
            <BasicModal
                open={openModal}
                title="Excluir Aula"
                handleClose={handleCloseModal}
                description="Tem certeza que deseja excluir a aula?"
                onDelete={deleteClasseById}
            />
            <ModalFilter
                open={openModalFilter}
                title="Filtros"
                handleClose={handleCloseModalFilter}
                content={
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                <TextField
                                    fullWidth
                                    id="outlined-course"
                                    type="text"
                                    label="Nome do Curso"
                                    value={nameCourse}
                                    onChange={(e) => dispatch({ type: 'SET_NAMECOURSE_CLASS', payload: e.target.value })}
                                    name="course"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Data de criação"
                                            value={valueDateClass}
                                            onChange={(e) => handleChangeDateClass(e)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
                            <Grid item>
                                <Box sx={{ mt: 2, mr: 3 }}>
                                    <AnimateButton>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="button"
                                            variant="contained"
                                            color="error"
                                            onClick={(e) => [
                                                dispatch({ type: 'SET_CLEAR_CLASS' }),
                                                setOpenModalFilter(false),
                                                getAllClasses()
                                            ]}
                                        >
                                            Limpar Busca
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ mt: 2, mr: 3 }}>
                                    <AnimateButton>
                                        <Button fullWidth size="large" type="submit" color="primary" variant="contained">
                                            Buscar
                                        </Button>
                                    </AnimateButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                }
            />
            <TableContainer sx={{ mt: 2, height: '100%', padding: 3 }} component={Paper}>
                <Grid container spacing={matchDownSM ? 2 : 2}>
                    <Grid item xs={12} sm={6} sx={{ marginTop: 3 }}>
                        <h1
                            style={{
                                font: 'normal normal bold 35px/44px Roboto, sans-serif',
                                letterSpacing: '0px',
                                color: 'var(--unnamed-color-015641)',
                                Color: '#015641',
                                opacity: 1,
                                padding: 15,
                                marginLeft: '2%'
                            }}
                        >
                            <FormatListBulletedIcon /> AULAS
                        </h1>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ marginTop: 8 }}>
                        <Box display="flex" justifyContent="right" alignItems="right" marginRight={2}>
                            <SearchField
                                placeholder="Pesquisar..."
                                onEnter={(e) => searchClasses(e)}
                                onSearchClick={(e) => searchClasses(e)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <hr style={{ width: '95%', marginTop: 0 }}></hr>
                <h3
                    style={{
                        font: 'normal normal 300 18px/22px Roboto, sans-serif',
                        letterSpacing: '0px',
                        Color: '#00000',
                        opacity: 1,
                        padding: 15,
                        marginLeft: '2%'
                    }}
                >
                    Gerencie suas aulas
                </h3>
                {error || success ? (
                    <Snackbar open={true} autoHideDuration={6000}>
                        <Alert
                            severity={error ? 'error' : success ? 'success' : ''}
                            sx={{
                                width: '100%',
                                backgroundColor: error ? 'red' : success ? 'green' : 'orange',
                                color: '#FFF'
                            }}
                        >
                            {error ? error : success ? success : ''}
                        </Alert>
                    </Snackbar>
                ) : (
                    ''
                )}
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Título</StyledTableCell>
                            <StyledTableCell>Número</StyledTableCell>
                            <StyledTableCell>Curso</StyledTableCell>
                            <StyledTableCell>Data de criação</StyledTableCell>
                            <StyledTableCell align="center">Ações</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {classes.data &&
                            classes.data.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>{row.title}</StyledTableCell>
                                    <StyledTableCell>{row.number}</StyledTableCell>
                                    <StyledTableCell>{row.course}</StyledTableCell>
                                    <StyledTableCell>{row.created_at}</StyledTableCell>
                                    <StyledTableCell style={{ width: 160 }} align="center">
                                        <Tooltip title="Visualizar">
                                            <IconButton onClick={() => navigate({ pathname: `/aula/${row.id}/view` })}>
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Editar">
                                            <IconButton onClick={() => navigate({ pathname: `/aula/${row.id}/edit` })}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Excluir">
                                            <IconButton onClick={() => handleOpenDestroy(row.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={3}
                                count={classes.total}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Registros por Página'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SpeedDial
                direction="left"
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                FabProps={{
                    sx: {
                        bgcolor: '#015641',
                        '&:hover': {
                            bgcolor: '#0a8f53'
                        }
                    }
                }}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        sx={{ display: 'flex', alignItems: 'center' }}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => (action.operation === 'filtros' ? [e.stopPropagation(), setOpenModalFilter(true)] : '')}
                    />
                ))}
            </SpeedDial>
        </>
    );
}
