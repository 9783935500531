import { app, blob } from './Api';

const getUser = async () => {
    return app.get('user');
};
const postUser = async (data) => {
    return app.post('user', data);
};
const getUsers = async (page, qtdPerPage, name) => {
    return app.get(`user?page=${page}&qtdPerPage=${qtdPerPage}&name=${name || ''}`);
};
const searchUser = async (name) => {
    return app.get(`user?name=${name || ''}`);
};
const getUserPerId = async (id) => {
    return app.get(`user/${id}`);
};
const updateUser = async (id, data) => {
    return app.put(`user/${id}`, data);
};
const deleteUser = async (id) => {
    return app.delete(`user/${id}`);
};
const getResourceFilterUser = async () => {
    return app.get(`get_resource_user`);
};
const getResourcerUser = async () => {
    return app.get(`get_resource_user_filter`);
};
const changeStatus = async (id) => {
    return app.put(`change_status_user/${id}`);
};
const loginUser = async (id) => {
    return app.post(`another_user/?id=${id}`);
};
const reportUser = async (id) => {
    return blob.get(`student_report/${id}`);
};
export {
    getUser,
    postUser,
    getUsers,
    getUserPerId,
    updateUser,
    deleteUser,
    getResourceFilterUser,
    getResourcerUser,
    searchUser,
    changeStatus,
    loginUser,
    reportUser
};
